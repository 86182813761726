<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pa-md-0>
      <v-flex xs12 align-self-center style="margin-all: 0px">
        <v-layout wrap justify-center>
          <v-flex
            xs12
            lg10
            :style="
              $vuetify.breakpoint.name == 'xs'
                ? 'margin-top:0%'
                : 'padding-top:0%'
            "
            align-self-center
          >
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12>
                    <v-card flat tile color="transparent">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-4>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 text-left>
                              <span
                                style="font-size: 24px; color: #000"
                                class="poppinsbold"
                              >
                                Back into the Wild: The Summary
                              </span></v-flex
                            >
                          </v-layout>
                          <v-layout
                            wrap
                            justify-center
                            class="hidden-sm-and-down"
                          >
                            <v-flex xs12 text-center>
                              <span
                                style="font-size: 20px; color: #000"
                                class="poppinsbold"
                              >
                                Back into the Wild: The Summary
                              </span></v-flex
                            >
                          </v-layout>
                          <span
                            class="poppinsregular"
                            :style="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'font-size:17px;color:#000'
                                : 'font-size:19px;color:#000'
                            "
                          >
                            <span
                              :style="
                                $vuetify.breakpoint.name == 'md'
                                  ? 'font-size:14px'
                                  : 'font-size:16px'
                              "
                            >
                              WTI’s wildlife rescue and rehabilitation project
                              in Assam, India rehabilitates orphaned elephant
                              and rhino calves back into the wild. At times,
                              elephant and rhino babies are orphaned and
                              abandoned in the wild following the tragic death
                              of their mothers, either due to electrocution,
                              poaching, conflict with people or during the
                              annual floods in Assam. To ensure their survival,
                              our trained team of veterinarians and animal
                              keepers bring them to our rehabilitation center,
                              where they are nurtured for 3-4 years until they
                              are ready to return home, into the wild.
                            </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6 text-center pa-4>
                    <v-img
                      contain
                      :src="require('./../../assets/images/btw1.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#527E33"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <span
                      style="
                        color: #6a768a;
                        font-style: italic;
                        font-size: 11px;
                        line-height: normal;
                      "
                      class="poppinsbold"
                    >
                      One of the elephant calves, Dhola, with his animal keeper,
                      Tarun, at CWRC | Photo: Samshul Ali
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex xs12 md12>
                    <v-card flat tile color="transparent">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-4>
                          <v-layout wrap class="hidden-md-and-up">
                            <v-flex xs12 text-left>
                              <span
                                style="font-size: 24px; color: #000"
                                class="poppinsbold"
                              >
                                The Challenge
                              </span></v-flex
                            >
                          </v-layout>
                          <v-layout
                            wrap
                            justify-center
                            class="hidden-sm-and-down"
                          >
                            <v-flex xs12 text-center>
                              <span
                                style="font-size: 20px; color: #000"
                                class="poppinsbold"
                              >
                                The Challenge
                              </span></v-flex
                            >
                          </v-layout>
                          <span
                            class="poppinsregular"
                            :style="
                              $vuetify.breakpoint.name == 'xs'
                                ? 'font-size:17px;color:#000'
                                : 'font-size:19px;color:#000'
                            "
                          >
                            <span
                              :style="
                                $vuetify.breakpoint.name == 'md'
                                  ? 'font-size:14px'
                                  : 'font-size:16px'
                              "
                            >
                              Following the death of its mother, the chances of
                              survival of juveniles in the wild are close to
                              nil. The orphaned calves, as young as 1-3 months
                              old, are either predated upon or starved to death.
                              They suffer severe trauma, and at the time of
                              their rescue, are often found in a terrible state
                              - dehydrated and emaciated. To survive, these
                              babies don&#39;t just need immediate
                              stabilization, but also long-term care,
                              nourishment and nurturing, for which we bring them
                              to our center.
                            </span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 md6 text-center pa-4>
                    <v-img
                      contain
                      :src="require('./../../assets/images/btw2.jpg')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#527E33"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <span
                      style="
                        color: #6a768a;
                        font-style: italic;
                        font-size: 11px;
                        line-height: normal;
                      "
                      class="poppinsbold"
                    >
                      Elephant calf, newly admitted to CWRC | Photo: Madhumay
                      Mallik
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center text-left pa-4>
                      <v-layout wrap justify-center class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span
                            style="font-size: 24px; color: black"
                            class="poppinsbold"
                          >
                            The Solution
                          </span></v-flex
                        >
                      </v-layout>
                      <v-layout wrap justify-center class="hidden-sm-and-down">
                        <v-flex xs12 text-center>
                          <span
                            style="font-size: 20px; color: black"
                            class="poppinsbold"
                          >
                            The Solution
                          </span></v-flex
                        >
                      </v-layout>
                      <span
                        style="text-align: justify"
                        class="poppinsregular"
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        "
                      >
                        <span
                          :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:16px'
                              : 'font-size:16px'
                          "
                        >
                          Established in 2002, the Centre for Wildlife
                          Rehabilitation and Conservation (CWRC), provides
                          nutrition, medicine and health care to orphaned
                          babies. They are hand-raised by a team of trained
                          veterinarians and animal keepers for 3-4 years and
                          prepared for a life in the wild, just like their own
                          mothers would. Once ready, they are relocated to a
                          suitable release site in the forest. As they slowly
                          acclimatize to their forever wild home, they are
                          fitted with satellite monitoring collars and released.
                          We keep a track of the released individuals for a few
                          months to observe their interactions with other
                          wildlife and also monitor them for their survival.
                        </span>
                      </span>
                    </v-flex>

                    <v-layout wrap justify-center>
                      <v-flex
                        xs12
                        sm12
                        md6
                        pa-4
                        pa-md-0
                        pa-lg-0
                        pa-xl-0
                        text-center
                      >
                        <v-img
                          :src="require('./../../assets/images/btw3.jpg')"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <p
                          style="
                            color: #6a768a;
                            font-style: italic;
                            font-size: 11px;
                            line-height: normal;
                          "
                          class="poppinsbold pa-1"
                        >
                          Rhino calf translocated to a temporary 'boma' or
                          enclosure within the Manas National Park. They slowly
                          explore and get used to their forever wild home, and
                          eventually show reluctance to return to the ‘boma’ in
                          a few months | Photo: Rathin Barman
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-start pa-md-4 pa-lg-0>
              <v-flex xs12 align-self-center>
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex xs12 align-self-center text-left pa-4>
                      <v-layout wrap justify-center class="hidden-md-and-up">
                        <v-flex xs12 text-left>
                          <span
                            style="font-size: 24px; color: black"
                            class="poppinsbold"
                          >
                            Forever Wild
                          </span></v-flex
                        >
                      </v-layout>
                      <v-layout wrap justify-center class="hidden-sm-and-down">
                        <v-flex xs12 text-center>
                          <span
                            style="font-size: 20px; color: black"
                            class="poppinsbold"
                          >
                            Forever Wild
                          </span></v-flex
                        >
                      </v-layout>
                      <span
                        style="text-align: justify"
                        class="poppinsregular"
                        :style="
                          $vuetify.breakpoint.name == 'xs'
                            ? 'font-size:17px;color:#000'
                            : 'font-size:19px;color:#000'
                        "
                      >
                        <span
                          :style="
                            $vuetify.breakpoint.name == 'md'
                              ? 'font-size:16px'
                              : 'font-size:16px'
                          "
                        >
                          Rehabilitation not only transforms individual lives
                          from tragedy to success, but also contributes
                          additional elephants and rhinos to their overall wild
                          population in India. There have been several instances
                          when our rehabilitated elephants have been seen
                          socializing with the wild herds, and rescued female
                          rhinos have birthed their own calves in the wild. At
                          CWRC, we have given a second chance at life to these
                          young ones and seek your support as we send them back
                          into the wild.
                        </span>
                      </span>
                    </v-flex>

                    <v-layout wrap justify-center>
                      <v-flex
                        xs12
                        sm12
                        md6
                        pa-4
                        pa-md-0
                        pa-lg-0
                        pa-xl-0
                        text-center
                      >
                        <v-img
                          :src="require('./../../assets/images/btw4.jpg')"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#527E33"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <p
                          style="
                            color: #6a768a;
                            font-style: italic;
                            font-size: 11px;
                            line-height: normal;
                          "
                          class="poppinsbold pa-1"
                        >
                          One of our released Rhino calves, Ganga, with her own
                          calf in the wild. Ganga is now a grandmother! | Photo:
                          Debojit Saikia
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2 pb-6 pa-md-4 pa-lg-0 py-lg-4>
              <v-flex xs12 align-self-center text-center pa-2>
                <v-btn
                  tile
                  large
                  dark
                  color="#e27725"
                  @click="
                    $vuetify.breakpoint.name == 'xs' ||
                    $vuetify.breakpoint.name == 'sm'
                      ? scrollMeXS()
                      : scrollMe()
                  "
                >
                  <span
                    class="poppinsbold"
                    :style="
                      $vuetify.breakpoint.name == 'xs'
                        ? 'font-size:17px'
                        : 'font-size:24px'
                    "
                  >
                    Help Keep Wildlife Forever Wild
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              justify-start
              pt-2
              pb-6
              pa-md-4
              pa-lg-0
              py-lg-4
              pb-lg-8
            >
              <v-flex xs12 align-self-center>
                <v-card outlined flat tile color="#527E33">
                  <v-layout wrap pa-2>
                    <v-flex xs12 sm3 align-self-center text-center pa-2>
                      <v-img
                        contain
                        height="100px"
                        :src="require('./../../assets/images/wtilogo.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="#527E33"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-flex>

                    <v-flex
                      xs12
                      sm9
                      align-self-center
                      text-sm-left
                      text-xs-center
                      pa-2
                      style="color: #ffffff"
                    >
                      <span class="poppinsbold">
                        FOR A SECURE NATURAL HERITAGE OF INDIA
                      </span>
                      <br />
                      <br />
                      <span class="poppinssemibold">
                        CONTACT INFO
                        <br />
                        <span class="poppinsregular">
                          Address: F-13, Sector 8, National Capital Region
                          (NCR), Noida 201301, India Email: info@wti.org.in
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  methods: {
    scrollMe() {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
    scrollMeXS() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      document.getElementById("donateNow").scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped>
.grad {
  background-image: linear-gradient(180deg, red, yellow);
}
.bannerGrad {
  background: transparent;

  background-image: linear-gradient(
    104deg,
    #bc4639 6%,
    #7e7e7e2a 94%,
    transparent 100%
  );
}
.rot {
  animation-duration: 3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
.texth {
  line-height: 1.5em;
  min-height: 9.5em;
  float: left;
  width: 100%;
}
.hovCls {
  color: #005f32;
  border-bottom: 3px solid #005f32;
}

.hovCls:hover {
  color: blue;
  border-bottom: 3px solid blue;
}
</style>
